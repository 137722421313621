import {
  EXCHANGES_TABLE_TYPES,
  COINS_TABLE_TYPES,
  NFT_TABLE_TYPES,
} from './constants'
import { intl } from 'src/i18n'

export const getTableViewConfig = (
  type = 'coin',
  tableType,
  currency = 'USD',
  filterPeriod = '24h',
  isMobile = false
) => {
  return {
    coin: {
      [COINS_TABLE_TYPES.DEFAULT]: {
        defaultSortField: 'marketCap',
        updateCell: isMobile
          ? [
              {
                index: 6,
                value: 'supply',
                animated: false,
                animationName: 'supplyAnimation',
              },
              {
                index: 2,
                value: 'price',
                animated: true,
                animationName: 'priceAnimation',
              },
              {
                index: 4,
                value: 'marketCap',
                animated: false,
                animationName: 'marketCapAnimation',
              },
              {
                index: 5,
                value: 'volume',
                animated: false,
                animationName: 'volumeAnimation',
              },
              {
                index: 3,
                value: 'change',
                animated: true,
                animationName: 'changeAnimation',
              },
            ]
          : [
              {
                index: 6,
                value: 'supply',
                animated: false,
                animationName: 'supplyAnimation',
              },
              {
                index: 4,
                value: 'price',
                animated: true,
                animationName: 'priceAnimation',
              },
              {
                index: 3,
                value: 'marketCap',
                animated: false,
                animationName: 'marketCapAnimation',
              },
              {
                index: 5,
                value: 'volume',
                animated: false,
                animationName: 'volumeAnimation',
              },
              {
                index: 7,
                value: 'change',
                animated: true,
                animationName: 'changeAnimation',
              },
            ],
        textHeader: intl.t('components.tableView.coin.textHeader'),
        mobileWidth: 1.95,
        mobileOverlayWidthParam: 0.32,
        mobileOverlayIncludeName: true,
        data: isMobile
          ? [
              {
                headerText: intl.t('components.tableView.coin.headerText1'),
                dataKey: 'name',
                sortArrowPosition: 'right',
                colWidth: 11,
                className: ['leftCell'],
                sortValue: 'name',
                stickyColumn: true,
              },
              {
                headerText: intl.t('components.tableView.coin.headerText2', {
                  currency,
                }),
                dataKey: 'price',
                sortArrowPosition: 'left',
                colWidth: 13,
                className: ['rightCell', 'updateCell'],
                sortValue: 'price',
              },
              {
                headerText: intl.t('components.tableView.coin.headerText3', {
                  filterPeriod:
                    filterPeriod === 'custom' ? '' : ` ${filterPeriod}`,
                }),
                dataKey: 'change',
                sortArrowPosition: 'left',
                colWidth: 8,
                className: ['rightCell'],
                sortValue: 'change',
              },
              {
                headerText: intl.t('components.tableView.coin.headerText4', {
                  currency,
                }),
                dataKey: 'marketCap',
                sortArrowPosition: 'left',
                colWidth: 11,
                className: ['rightCell'],
                sortValue: 'marketCap',
              },
              {
                headerText: intl.t('components.tableView.coin.headerText5'),
                dataKey: 'volume',
                sortArrowPosition: 'left',
                colWidth: 13,
                className: ['rightCell'],
                sortValue: 'volume',
              },
              {
                headerText: intl.t('components.tableView.coin.headerText6'),
                dataKey: 'supply',
                sortArrowPosition: 'left',
                colWidth: 13,
                className: ['rightCell'],
                sortValue: 'emission',
              },
              {
                headerText: intl.t('components.tableView.coin.headerText7'),
                dataKey: 'graph',
                colWidth: 18,
                className: ['rightCell'],
              },
            ]
          : [
              {
                headerText: intl.t('components.tableView.coin.headerText8'),
                dataKey: 'symbol',
                sortArrowPosition: 'right',
                colWidth: 7,
                className: ['leftCell'],
                sortValue: 'symbol',
              },
              {
                headerText: intl.t('components.tableView.coin.headerText1'),
                dataKey: 'name',
                sortArrowPosition: 'right',
                colWidth: 11,
                className: ['leftCell'],
                sortValue: 'name',
                stickyColumn: true,
                left: 5,
              },
              {
                headerText: '',
                dataKey: 'buy',
                colWidth: 10,
                className: ['centerCell'],
              },
              {
                headerText: intl.t('components.tableView.coin.headerText10', {
                  currency,
                }),
                dataKey: 'price',
                sortArrowPosition: 'left',
                colWidth: 13,
                className: ['rightCell', 'updateCell'],
                sortValue: 'price',
              },
              {
                headerText: intl.t('components.tableView.coin.headerText13', {
                  filterPeriod:
                    filterPeriod === 'custom' ? '' : ` ${filterPeriod}`,
                }),
                dataKey: 'change',
                sortArrowPosition: 'left',
                colWidth: 11,
                className: ['rightCell'],
                sortValue: 'change',
              },
              {
                headerText: intl.t('components.tableView.coin.headerText11'),
                dataKey: 'volume',
                sortArrowPosition: 'left',
                colWidth: 13,
                className: ['rightCell'],
                sortValue: 'volume',
              },
              {
                headerText: intl.t('components.tableView.coin.headerText9', {
                  currency,
                }),
                dataKey: 'marketCap',
                sortArrowPosition: 'left',
                colWidth: 14,
                className: ['rightCell'],
                sortValue: 'marketCap',
              },
              {
                headerText: intl.t('components.tableView.coin.headerText12'),
                dataKey: 'supply',
                sortArrowPosition: 'left',
                colWidth: 13,
                className: ['rightCell'],
                sortValue: 'emission',
              },
              {
                headerText: intl.t('components.tableView.coin.headerText7'),
                dataKey: 'graph',
                colWidth: 15,
                className: ['rightCell'],
              },
            ],
      },
      [COINS_TABLE_TYPES.WATCHLIST]: {
        defaultSortField: 'marketCap',
        updateCell: isMobile
          ? [
              {
                index: 6,
                value: 'supply',
                animated: false,
                animationName: 'supplyAnimation',
              },
              {
                index: 2,
                value: 'price',
                animated: true,
                animationName: 'priceAnimation',
              },
              {
                index: 4,
                value: 'marketCap',
                animated: false,
                animationName: 'marketCapAnimation',
              },
              {
                index: 5,
                value: 'volume',
                animated: false,
                animationName: 'volumeAnimation',
              },
              {
                index: 3,
                value: 'change',
                animated: true,
                animationName: 'changeAnimation',
              },
            ]
          : [
              { index: 3, value: 'price', animated: true },
              { index: 2, value: 'marketCap', animated: false },
              { index: 4, value: 'volume', animated: false },
              { index: 5, value: 'change', animated: true },
            ],
        mobileWidth: 1.95,
        mobileOverlayWidthParam: 0.32,
        mobileOverlayIncludeName: true,
        textHeader: intl.t('components.tableView.watchList.textHeader'),
        data: isMobile
          ? [
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText1'
                ),
                dataKey: 'watchListName',
                sortArrowPosition: 'right',
                colWidth: 10,
                className: ['leftCell'],
                sortValue: 'symbol',
                stickyColumn: true,
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText2',
                  { currency }
                ),
                dataKey: 'price',
                sortArrowPosition: 'left',
                colWidth: 13,
                className: ['rightCell', 'updateCell'],
                sortValue: 'price',
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText3',
                  {
                    filterPeriod:
                      filterPeriod === 'custom' ? '' : ` ${filterPeriod}`,
                  }
                ),
                dataKey: 'change',
                sortArrowPosition: 'left',
                colWidth: 8,
                className: ['rightCell'],
                sortValue: 'change',
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText4',
                  { currency }
                ),
                dataKey: 'marketCap',
                sortArrowPosition: 'left',
                colWidth: 11,
                className: ['rightCell'],
                sortValue: 'marketCap',
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText5'
                ),
                dataKey: 'volume',
                sortArrowPosition: 'left',
                colWidth: 13,
                className: ['rightCell'],
                sortValue: 'volume',
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText6'
                ),
                dataKey: 'supply',
                sortArrowPosition: 'left',
                colWidth: 13,
                className: ['rightCell'],
                sortValue: 'emission',
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText7'
                ),
                dataKey: 'graph',
                colWidth: 18,
                className: ['rightCell'],
              },
            ]
          : [
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText1'
                ),
                dataKey: 'watchListName',
                sortArrowPosition: 'right',
                colWidth: 14,
                className: ['leftCell'],
                sortValue: 'name',
                stickyColumn: true,
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText8',
                  { currency }
                ),
                dataKey: 'marketCap',
                sortArrowPosition: 'left',
                colWidth: 13,
                className: ['rightCell'],
                sortValue: 'marketCap',
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText9',
                  { currency }
                ),
                dataKey: 'price',
                sortArrowPosition: 'left',
                colWidth: 10,
                className: ['rightCell', 'updateCell'],
                sortValue: 'price',
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText10',
                  {
                    filterPeriod:
                      filterPeriod === 'custom' ? '' : ` ${filterPeriod}`,
                    currency: currency,
                  }
                ),
                dataKey: 'volume',
                sortArrowPosition: 'left',
                colWidth: 13,
                className: ['rightCell'],
                sortValue: 'volume',
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText11',
                  {
                    filterPeriod:
                      filterPeriod === 'custom' ? '' : ` ${filterPeriod}`,
                  }
                ),
                dataKey: 'change',
                sortArrowPosition: 'left',
                colWidth: 11,
                className: ['rightCell'],
                sortValue: 'change',
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText12'
                ),
                dataKey: 'amount',
                colWidth: 8,
                className: ['rightCell', 'editable'],
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText13'
                ),
                dataKey: 'tradePrice',
                colWidth: 13,
                className: ['rightCell', 'editable'],
              },
              {
                headerText: intl.t(
                  'components.tableView.watchList.headerText14'
                ),
                dataKey: 'pnl',
                colWidth: 14,
                className: ['rightCell', 'pnlCell'],
              },
            ],
      },
    },
    exchange: {
      [EXCHANGES_TABLE_TYPES.OVERVIEW]: {
        updateCell: isMobile
          ? [
              {
                index: 2,
                value: 'price',
                animated: false,
                animationName: 'supplyAnimation',
              },
              {
                index: 4,
                value: 'marketCap',
                animated: true,
                animationName: 'priceAnimation',
              },
              {
                index: 5,
                value: 'volumeReported',
                animated: false,
                animationName: 'marketCapAnimation',
              },
              {
                index: 6,
                value: 'supply',
                animated: false,
                animationName: 'volumeAnimation',
              },
              {
                index: 3,
                value: 'change',
                animated: true,
                animationName: 'changeAnimation',
              },
              {
                index: 7,
                value: 'chart',
                animated: true,
                animationName: 'changeAnimation',
              },
            ]
          : [{ index: 2, value: 'volumeReported', animated: true }],
        defaultSortField: 'type',
        mobileWidth: 2.2,
        mobileOverlayWidthParam: 0.39,
        textHeader: intl.t('components.tableView.exchange.textHeader'),
        data: isMobile
          ? [
              {
                headerText: intl.t('components.tableView.exchange.headerText1'),
                sortArrowPosition: 'right',
                dataKey: 'exchangeName',
                sortValue: 'name',
                additionalKey: 'coreType',
                colWidth: 10,
                className: ['leftCell'],
                stickyColumn: true,
              },
              {
                headerText: intl.t(
                  'components.tableView.exchange.headerText2',
                  {
                    filterPeriod:
                      filterPeriod === 'custom' ? '' : ` ${filterPeriod}`,
                  }
                ),
                dataKey: 'volumeReported',
                sortArrowPosition: 'left',
                sortValue: 'volumeReported',
                colWidth: 13,
                className: ['rightCell', 'updateCell'],
              },
              {
                headerText: intl.t('components.tableView.exchange.headerText3'),
                dataKey: 'pairs',
                sortArrowPosition: 'left',
                sortValue: 'pairs',
                colWidth: 11,
                className: ['rightCell'],
              },
              {
                headerText: intl.t('components.tableView.exchange.headerText4'),
                dataKey: 'coins',
                sortArrowPosition: 'left',
                sortValue: 'coins',
                colWidth: 11,
                className: ['rightCell'],
              },
              {
                headerText: intl.t(
                  'components.tableView.exchange.headerText5',
                  {
                    filterPeriod:
                      filterPeriod === 'custom' ? '' : ` ${filterPeriod}`,
                  }
                ),
                dataKey: 'change',
                sortArrowPosition: 'left',
                sortValue: 'changeReported',
                colWidth: 11,
                className: ['rightCell'],
              },
              {
                headerText: intl.t('components.tableView.exchange.headerText6'),
                dataKey: 'visits',
                sortArrowPosition: 'left',
                sortValue: 'visits',
                colWidth: 13,
                className: ['rightCell'],
              },
              {
                headerText: '',
                dataKey: '',
                sortValue: '',
                colWidth: 2,
                className: ['rightCell'],
              },
              {
                headerText: intl.t('components.tableView.exchange.headerText7'),
                dataKey: 'type',
                sortArrowPosition: 'right',
                sortValue: 'type',
                colWidth: 8,
                className: ['leftCell'],
              },
            ]
          : [
              {
                headerText: intl.t('components.tableView.exchange.headerText1'),
                sortArrowPosition: 'right',
                dataKey: 'exchangeName',
                sortValue: 'name',
                additionalKey: 'coreType',
                colWidth: 13,
                className: ['leftCell'],
              },
              {
                headerText: intl.t(
                  'components.tableView.exchange.headerText8',
                  {
                    filterPeriod:
                      filterPeriod === 'custom' ? '' : ` ${filterPeriod}`,
                  }
                ),
                dataKey: 'volumeReported',
                sortArrowPosition: 'left',
                sortValue: 'volumeReported',
                colWidth: 12,
                className: ['rightCell', 'updateCell'],
              },
              {
                headerText: intl.t(
                  'components.tableView.exchange.headerText5',
                  {
                    filterPeriod:
                      filterPeriod === 'custom' ? '' : ` ${filterPeriod}`,
                  }
                ),
                dataKey: 'change',
                sortArrowPosition: 'left',
                sortValue: 'changeReported',
                colWidth: 11,
                className: ['rightCell'],
              },
              {
                headerText: intl.t('components.tableView.exchange.headerText3'),
                dataKey: 'pairs',
                sortArrowPosition: 'left',
                sortValue: 'pairs',
                colWidth: 10,
                className: ['rightCell'],
              },
              {
                headerText: intl.t('components.tableView.exchange.headerText4'),
                dataKey: 'coins',
                sortArrowPosition: 'left',
                sortValue: 'coins',
                colWidth: 11,
                className: ['rightCell'],
              },
              {
                headerText: intl.t('components.tableView.exchange.headerText6'),
                dataKey: 'visits',
                sortArrowPosition: 'left',
                sortValue: 'visits',
                colWidth: 11,
                className: ['rightCell'],
              },
              {
                headerText: '',
                dataKey: '',
                sortValue: '',
                colWidth: 5,
                className: ['rightCell'],
              },
              {
                headerText: intl.t('components.tableView.exchange.headerText7'),
                dataKey: 'type',
                sortArrowPosition: 'right',
                sortValue: 'type',
                colWidth: 7,
                className: ['leftCell'],
              },
            ],
      },
      [EXCHANGES_TABLE_TYPES.SOCIALS]: {
        updateCell: isMobile
          ? [
              {
                index: 2,
                value: 'price',
                animated: false,
                animationName: 'supplyAnimation',
              },
              {
                index: 4,
                value: 'marketCap',
                animated: true,
                animationName: 'priceAnimation',
              },
              {
                index: 5,
                value: 'volumeReported',
                animated: false,
                animationName: 'marketCapAnimation',
              },
              {
                index: 6,
                value: 'supply',
                animated: false,
                animationName: 'volumeAnimation',
              },
              {
                index: 3,
                value: 'change',
                animated: true,
                animationName: 'changeAnimation',
              },
              {
                index: 7,
                value: 'chart',
                animated: true,
                animationName: 'changeAnimation',
              },
            ]
          : [{ index: 2, value: 'volumeReported', animated: true }],
        mobileWidth: 2.4,
        mobileOverlayWidthParam: 0.39,
        defaultSortField: 'volumeReported',
        textHeader: intl.t('components.tableView.exchangeSocials.textHeader'),
        data: isMobile
          ? [
              {
                headerText: intl.t(
                  'components.tableView.exchangeSocials.headerText1'
                ),
                sortArrowPosition: 'right',
                dataKey: 'exchangeName',
                sortValue: 'name',
                additionalKey: 'coreType',
                colWidth: 8,
                className: ['leftCell'],
                stickyColumn: true,
              },
              {
                headerText: intl.t(
                  'components.tableView.exchangeSocials.headerText2'
                ),
                dataKey: 'country',
                sortArrowPosition: 'right',
                sortValue: 'country',
                colWidth: 11,
                className: ['leftCell'],
              },
              {
                headerText: intl.t(
                  'components.tableView.exchangeSocials.headerText3'
                ),
                dataKey: 'launched',
                sortArrowPosition: 'right',
                sortValue: 'launched',
                colWidth: 9,
                className: ['leftCell'],
              },
              {
                headerText: intl.t(
                  'components.tableView.exchangeSocials.headerText4'
                ),
                dataKey: 'twitter',
                sortArrowPosition: 'left',
                sortValue: 'twitter',
                colWidth: 11,
                className: ['rightCell'],
              },
              {
                headerText: intl.t(
                  'components.tableView.exchangeSocials.headerText5'
                ),
                dataKey: 'reddit',
                sortArrowPosition: 'left',
                sortValue: 'reddit',
                colWidth: 11,
                className: ['rightCell'],
              },
              {
                headerText: intl.t(
                  'components.tableView.exchangeSocials.headerText6'
                ),
                dataKey: 'trustPilotScore',
                sortArrowPosition: 'left',
                sortValue: 'trustPilotScore',
                colWidth: 15,
                className: ['rightCell'],
              },
            ]
          : [
              {
                headerText: intl.t(
                  'components.tableView.exchangeSocials.headerText1'
                ),
                sortArrowPosition: 'right',
                dataKey: 'exchangeName',
                sortValue: 'name',
                additionalKey: 'coreType',
                colWidth: 13,
                className: ['leftCell'],
              },
              {
                headerText: intl.t(
                  'components.tableView.exchangeSocials.headerText2'
                ),
                dataKey: 'country',
                sortArrowPosition: 'right',
                sortValue: 'country',
                colWidth: 11,
                className: ['leftCell'],
              },
              {
                headerText: intl.t(
                  'components.tableView.exchangeSocials.headerText3'
                ),
                dataKey: 'launched',
                sortArrowPosition: 'right',
                sortValue: 'launched',
                colWidth: 9,
                className: ['leftCell'],
              },
              {
                headerText: intl.t(
                  'components.tableView.exchangeSocials.headerText4'
                ),
                dataKey: 'twitter',
                sortArrowPosition: 'left',
                sortValue: 'twitter',
                colWidth: 11,
                className: ['rightCell'],
              },
              {
                headerText: intl.t(
                  'components.tableView.exchangeSocials.headerText5'
                ),
                dataKey: 'reddit',
                sortArrowPosition: 'left',
                sortValue: 'reddit',
                colWidth: 11,
                className: ['rightCell'],
              },
              {
                headerText: intl.t(
                  'components.tableView.exchangeSocials.headerText6'
                ),
                dataKey: 'trustPilotScore',
                sortArrowPosition: 'left',
                sortValue: 'trustPilotScore',
                colWidth: 15,
                className: ['rightCell'],
              },
            ],
      },
    },
    nft: {
      [NFT_TABLE_TYPES.DEFAULT]: {
        updateCell: isMobile
          ? [
              {
                index: 2,
                value: 'volumeTraded',
                animated: false,
                animationName: 'volumeAnimation',
              },
              {
                index: 3,
                value: 'marketCap',
                animated: true,
                animationName: 'marketCapAnimation',
              },
              {
                index: 4,
                value: 'floorPrice',
                animated: true,
                animationName: 'priceAnimation',
              },
              {
                index: 5,
                value: 'change',
                animated: true,
                animationName: 'changeAnimation',
              },
              {
                index: 6,
                value: 'avgPrice',
                animated: false,
                animationName: 'priceAnimation',
              },
              {
                index: 7,
                value: 'numSales',
                animated: false,
                animationName: 'marketCapAnimation',
              },
              {
                index: 8,
                value: 'items',
                animated: true,
                animationName: 'marketCapAnimation',
              },
            ]
          : [
              {
                index: 2,
                value: 'volumeTraded',
                animated: false,
                animationName: 'volumeAnimation',
              },
              {
                index: 3,
                value: 'marketCap',
                animated: true,
                animationName: 'marketCapAnimation',
              },
              {
                index: 4,
                value: 'floorPrice',
                animated: true,
                animationName: 'priceAnimation',
              },
              {
                index: 5,
                value: 'change',
                animated: true,
                animationName: 'changeAnimation',
              },
            ],
        defaultSortField: 'volumeTraded',
        mobileWidth: 2.2,
        mobileOverlayWidthParam: 0.39,
        textHeader: intl.t('components.tableView.nft.textHeader'),
        data: isMobile
          ? [
              {
                headerText: intl.t('components.tableView.nft.headerText1'),
                sortArrowPosition: 'right',
                dataKey: 'name',
                sortValue: 'name',
                colWidth: 10,
                className: ['leftCell'],
                stickyColumn: true,
              },
              {
                headerText: intl.t('components.tableView.nft.headerText2', {
                  currency,
                }),
                dataKey: 'volume',
                sortArrowPosition: 'left',
                sortValue: 'volume',
                colWidth: 10,
                className: ['rightCell', 'updateCell'],
              },
              {
                headerText: intl.t('components.tableView.nft.headerText3', {
                  currency,
                }),
                dataKey: 'marketCap',
                sortArrowPosition: 'left',
                sortValue: 'marketCap',
                colWidth: 11,
                className: ['rightCell', 'updateCell'],
              },
              {
                headerText: intl.t('components.tableView.nft.headerText4'),
                dataKey: 'floorPrice',
                sortArrowPosition: 'left',
                sortValue: 'floorPrice',
                colWidth: 11,
                className: ['rightCell', 'updateCell'],
              },
              {
                headerText: intl.t('components.tableView.nft.headerText5'),
                dataKey: 'change',
                sortArrowPosition: 'left',
                sortValue: 'change',
                colWidth: 8,
                className: ['rightCell', 'updateCell'],
              },
            ]
          : [
              {
                headerText: intl.t('components.tableView.nft.headerText1'),
                sortArrowPosition: 'right',
                dataKey: 'name',
                sortValue: 'name',
                additionalKey: 'coreType',
                colWidth: 13,
                className: ['leftCell'],
                stickyColumn: true,
              },
              {
                headerText: intl.t('components.tableView.nft.headerText2', {
                  currency,
                }),
                dataKey: 'volume',
                sortArrowPosition: 'left',
                sortValue: 'volume',
                colWidth: 10,
                className: ['rightCell', 'updateCell'],
              },
              {
                headerText: intl.t('components.tableView.nft.headerText3', {
                  currency,
                }),
                dataKey: 'marketCap',
                sortArrowPosition: 'left',
                sortValue: 'marketCap',
                colWidth: 11,
                className: ['rightCell', 'updateCell'],
              },
              {
                headerText: intl.t('components.tableView.nft.headerText4'),
                dataKey: 'floorPrice',
                sortArrowPosition: 'left',
                sortValue: 'floorPrice',
                colWidth: 11,
                className: ['rightCell', 'updateCell'],
              },
              {
                headerText: intl.t('components.tableView.nft.headerText5'),
                dataKey: 'change',
                sortArrowPosition: 'left',
                sortValue: 'change',
                colWidth: 8,
                className: ['rightCell', 'updateCell'],
              },
              {
                headerText: intl.t('components.tableView.nft.headerText6'),
                dataKey: 'numberOfOwners',
                sortArrowPosition: 'left',
                sortValue: 'numberOfOwners',
                colWidth: 8,
                className: ['rightCell'],
              },
              {
                headerText: intl.t('components.tableView.nft.headerText7'),
                dataKey: 'numberOfItems',
                sortArrowPosition: 'right',
                sortValue: 'numberOfItems',
                colWidth: 8,
                className: ['rightCell'],
              },
            ],
      },
    },
  }[type][tableType]
}
